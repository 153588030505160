export const setNavbar = {
  methods: {
    setNavbar(visible) {
      let data = {
        visible: visible
      };
      this.$store.dispatch("SET_NAVBAR", data);
    }
  }
};
