<template>
  <div>
    <div class="subHeader base noFlex">
      <div class="module-header">
        <h2>회원관리</h2>
      </div>
      <div class="contWrap flex">
        <div class="cont">
          <h4>
            <span class="material-icons">account_circle</span> 전체 가입자
          </h4>
          <h3>
            {{ totalUser }}
            <span>명</span>
          </h3>
        </div>
        <div class="cont">
          <h4>
            <span class="material-icons">account_circle</span> 이번달 가입자
          </h4>
          <h3>
            {{ thisMonthJoinUser }}
            <span>명</span>
          </h3>
        </div>
        <div class="cont">
          <h4>
            <span class="material-icons">account_circle</span> 오늘 가입자
          </h4>
          <h3>
            {{ dayJoinUser }}
            <span>명</span>
          </h3>
        </div>
        <div class="cont">
          <h4>
            <span class="material-icons">account_circle</span> 회원 탈퇴 수
          </h4>
          <h3>
            {{ dropUser }}
            <span>명</span>
          </h3>
        </div>
      </div>
      <div class="wtBox">
        <div class="module-filter">
          <!-- <span>검색</span> -->
          <div class="module-button">
            <select v-model="inputMode">
              <option disabled value="">선택</option>
              <option value="username">이름</option>
              <option value="email">이메일</option>
              <option value="mobileNo">전화번호</option>
            </select>
          </div>
          <div class="search">
            <fieldset>
              <legend>검색</legend>
              <input
                type="search"
                placeholder="검색어를 입력하세요"
                v-model="searchWord"
                @keyup.enter="search"
              />
              <button type="submit" class="material-icons" @click="search">
                search
              </button>
            </fieldset>
          </div>
          <button type="button" class="icoTxt" @click="reload">
            <i class="material-icons">replay</i>
            <b>새로고침</b>
          </button>
          <!-- <div class="module-button">
            <button type="button" class="blue" @click="getUserList">
              검색
            </button>
          </div> -->
        </div>
      </div>

      <div class="module-config">
        <div class="wtBox config-list">
          <header class="flexL">
            <h3>회원 리스트</h3>
            <span class="rubberBand"></span>
          </header>
          <table class="module-table lineRow">
            <tr>
              <th scope="col">가입일</th>
              <th scope="col">구분</th>
              <th scope="col">이름</th>
              <th scope="col">이메일</th>
              <th scope="col">전화번호</th>
              <!-- <th scope="col">유료이용</th> -->
              <th scope="col">포인트</th>
              <th scope="col">탈퇴</th>
            </tr>

            <tr v-for="(item, index) of userList" :key="index">
              <td>{{ moment(item.created_at).format("YYYY.MM.DD") }}</td>
              <td>{{ item.type ? item.type : "email" }}</td>
              <td>
                <router-link :to="`/admin/usersDetail?id=${item._id}`">{{
                  item.username
                }}</router-link>
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.mobileNo }}</td>
              <!-- <td>{{ item.isPaid == true ? "유료이용" : "무료이용" }}</td> -->
              <td>{{ numberFormat(item.point) }}P</td>
              <td class="flex">
                <!-- <button
                  type="button"
                  class="btnView"
                  @click="handleMenu(item.id)"
                >
                  상세보기
                </button> -->
                <button
                  type="button"
                  style="margin-left: 5px"
                  class="btnView red"
                  @click="removeUser(item.id)"
                >
                  탈퇴
                </button>
              </td>
            </tr>
          </table>

          <div class="pagination" v-if="total > 0">
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-show="total > 1" class="module-paging">
      <div class="inner">
        <ul class="skip prev">
          <li>
            <a href="#" @click="handlePaging(currentPage - 1)">
              <i class="material-icons">chevron_left</i>
            </a>
          </li>
        </ul>

        <ul class="paging">
          <li v-for="(page, i) in total" :key="i">
            <a
              href="#"
              :class="{ active: currentPage == i + 1 }"
              @click="handlePaging(page)"
              >{{ page }}</a
            >
          </li>
        </ul>

        <ul class="skip next">
          <li>
            <a href="#" @click="handlePaging(currentPage + 1)">
              <i class="material-icons">chevron_right</i>
            </a>
          </li>
        </ul>
      </div>
    </div> -->
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { fetchUserList, deleteUser, fetchUserDashboard } from "@/api/member";
import moment from "moment";
// import sha256 from "js-sha256";
// import DetailInstruct from "./DetailInstruct";
import { mapState } from "vuex";
import { eventBus } from "@/main";
import { setNavbar } from "@/mixins/setNavbar";
import { ko } from "vuejs-datepicker/dist/locale";
import { format } from "@/mixins/format";

export default {
  mixins: [setNavbar, format],
  data() {
    return {
      isEdit: false,
      ko: ko,
      moment: moment,
      display: true,
      currentComponent: null,
      displayConfirm: false,
      userList: [],
      inputMode: "",
      searchWord: "",
      currentPage: 1,
      total: 0,
      typeCode: "",
      startYmd: "",
      endYmd: "",
      periodCode: "",
      totalUser: "",
      dayJoinUser: "",
      thisMonthJoinUser: "",
      dropUser: "",
      userType: "",
    };
  },
  mounted() {
    // this.email = this.userInfo.email;
    eventBus.$on("closeConfirm", () => {
      this.closeConfirm();
    });
    this.getUserList();
    this.getDashboard();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    viewComponent(view) {
      this.currentComponent = view;
      // this.displayConfirm = true;
    },
    closeConfirm() {
      this.displayConfirm = false;
    },
    closeModal() {
      eventBus.$emit("closeModal", true);
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleClose() {
      setTimeout(() => {
        eventBus.$emit("closeModal", true);
      }, 2000);
    },

    confirm() {
      this.closeModal();
    },
    handleEditMode() {
      this.isEdit = !this.isEdit;
    },

    handleClick(components) {
      event.preventDefault();
      let routeData = this.$router.resolve({ name: components });
      window.open(routeData.href, "_blank");
    },
    handlePaging(page) {
      if (page > this.total) return false;
      else if (page < 1) return false;
      this.currentPage = page;
      this.getUserList();
    },
    handleMenu(userId) {
      this.$router.push({ name: "userDetail", query: { id: userId } });
    },
    removeUser(id) {
      let result = confirm("회원을 탈퇴 시키겠습니까?");
      if (result == false) {
        return;
      }
      deleteUser(id).then((res) => {
        if (res.status == 200) {
          this.getUserList();
        } else {
          return alert(res.data.message);
        }
      });
    },
    reload() {
      this.currentPage = 1;
      this.searchWord = "";
      this.inputMode = "";
      this.getUserList();
    },
    search() {
      if (this.inputMode == "") {
        return alert("검색 타입을 선택해 주세요.");
      }
      this.getUserList();
    },
    getUserList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        // typeCode: this.typeCode
        // startYmd: this.startYmd ? moment(this.startYmd).format("YYYYMMDD") : "",
        // endYmd: this.endYmd ? moment(this.endYmd).format("YYYYMMDD") : "",
        // periodCode: this.periodCode
      };
      fetchUserList(params).then((res) => {
        if (res.status == 200) {
          this.userList = res.data.users;
          this.currentPage = res.data.currentPage;
          this.total = res.data.total;
        } else {
          return alert(res.data.message);
        }
      });
    },
    getDashboard() {
      fetchUserDashboard().then((res) => {
        if (res.data.status == 200) {
          this.totalUser = res.data.totalUser;
          this.dayJoinUser = res.data.dayJoinUser;
          this.thisMonthJoinUser = res.data.thisMonthJoinUser;
          this.dropUser = res.data.dropUser;
        } else {
          return alert(res.data.message);
        }
      });
    },
  },
};
</script>
